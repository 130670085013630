"use strict";

class ScrollSlider {
	constructor(options = {}) {
		this.defaults = {
			root: null,
			scrollableSelector: "ul",
			itemSelector: "li",
			onInit: null,
		};

		this.options = { ...this.defaults, ...options };
		if (!this.options.root) {
			console.error("'Root' neexistuje");
			return;
		}
		this._root = this.options.root;
		this._root.classList.add("scrollslider-js");

		this.scrollableElement = this._root.querySelector(
			this.options.scrollableSelector
		);
		if (!this.scrollableElement) {
			console.error(
				`Pro selektor '${this.options.scrollableSelector}' nebyl nalezen žádný element`
			);
			return;
		}

		this.items = [
			...this.scrollableElement.querySelectorAll(this.options.itemSelector),
		];
		this._setup();
	}

	get root() {
		return this._root;
	}

	makeArrows(params) {
		const defaults = {
			leftArrowContent: "",
			leftArrowClass: null,
			rightArrowContent: "",
			rightArrowClass: null,
		};

		const arrowParams = { ...defaults, ...params };

		const buttonListener = (event) => {
			event.preventDefault();
			const current = this._root.querySelector(
				`${this.options.itemSelector}[data-is-current=true]`
			);

			if (current) {
				const neighbour =
					event.currentTarget.dataset.direction === "left"
						? current.previousElementSibling
						: current.nextElementSibling;
				const inline =
					event.currentTarget.dataset.direction === "left"
						? "end"
						: "start";
				neighbour.scrollIntoView({
					behavior: "smooth",
					block: "nearest",
					inline: inline,
				});
			}
		};

		this.btnLeft = document.createElement("button");
		this.btnLeft.dataset.direction = "left";
		this.btnLeft.setAttribute("aria-hidden", "true");
		this.btnLeft.addEventListener("click", buttonListener, { capture: true });
		this.btnLeft.innerHTML = arrowParams.leftArrowContent;

		if (arrowParams.leftArrowClass) {
			this.btnLeft.className = arrowParams.leftArrowClass;
		}

		this.btnRight = document.createElement("button");
		this.btnRight.dataset.direction = "right";
		this.btnRight.setAttribute("aria-hidden", "true");
		this.btnRight.addEventListener("click", buttonListener, {
			capture: true,
		});
		this.btnRight.innerHTML = arrowParams.rightArrowContent;

		if (arrowParams.rightArrowClass) {
			this.btnRight.className = arrowParams.rightArrowClass;
		}

		return {
			leftArrow: this.btnLeft,
			rightArrow: this.btnRight,
		};
	}

	_setup() {
		this.attachObserver();
		window.addEventListener("resize", this.attachObserver.bind(this));

		if (this.options.onInit) {
			this.options.onInit(this);
		}
	}

	attachObserver() {
		if (this.observer) {
			this.observer.disconnect();
		}

		const css = window.getComputedStyle(this.scrollableElement);

		const callback = (entries) => {
			entries.forEach((entry) => {
				entry.target.dataset.isCurrent = (
					entry.intersectionRatio === 1
				).toString();
			});

			if (this.btnLeft) {
				this.btnLeft.disabled =
					this._root.querySelector(
						`${this.options.itemSelector}:first-child`
					).dataset.isCurrent === "true";
			}

			if (this.btnRight) {
				this.btnRight.disabled =
					this._root.querySelector(
						`${this.options.itemSelector}:last-child`
					).dataset.isCurrent === "true";
			}
		};

		this.observer = new IntersectionObserver(callback, {
			root: this._root,
			/* rootMargin: `0px -${parseInt(css.paddingRight) - 5}px 0px -${
				parseInt(css.paddingLeft) - 5
			}px`, */
			rootMargin: `0px -${parseInt(css.paddingRight)}px 0px -${parseInt(
				css.paddingLeft
			)}px`,
			threshold: [1.0],
		});

		this.items.forEach((item) => {
			this.observer.observe(item);
		});
	}
}
